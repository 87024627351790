import {onDomChanges} from "../../components/dynamic/observer";
import {singleInit} from "../../components/dynamic/bind";
import IMask from "imask";
import axios from "axios";

function init() {
    document.querySelectorAll('[data-floating-field] input, [data-floating-field] textarea').forEach((input) => {
        const field = input.closest('[data-floating-field]');
        if (field.dataset.initialized) {
            return;
        }
        field.dataset.initialized = 'true';
        const checkValue = () => {
            if (input.value) {
                field.classList.add('_filled');
            } else {
                field.classList.remove('_filled');
            }
        };
        input.addEventListener('keyup', checkValue);
        checkValue();
    });
}


onDomChanges(() => {
    init();
});