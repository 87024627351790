import Jax from "../../components/jax/jax.js";
import IMask from "imask";
import {onDomChanges} from "../../components/dynamic/observer";


const initPhoneField = () => {
  document.querySelectorAll('[data-phone-field]:not([data-initialized])').forEach((input) => {
    input.dataset.initialized = "true";

    const mask = IMask(input, {
      mask: '+{7} (000) 000-00-00'
    });
  });
}

onDomChanges(initPhoneField);